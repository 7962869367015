<template>
  <div class="game-wrapper game">
    <div class="game-inner">
      <v-card-text class="pt-md-0 pt-sm-6">
        <div
          class="ms-0 align-start"
        >
          <v-row class="px-1 py-1">
            <v-col
              cols="12"
              class="py-1 px-3 d-flex justify-space-between align-center"
            >
              <div class="d-flex">
                <h1 class="text-h6 font-weight-bold black--text">
                  {{ $t('game.match_detail') }}
                </h1>
              </div>

              <div>
                <v-btn
                  v-if="!privateLanding"
                  class="ma-0 py-2 px-0 no-bg-hover font-weight-bold text-body-1"
                  style="
                    text-transform: inherit;
                    min-width: 60px;
                  "
                  text
                  color="secondary"
                  @click="onLogin"
                >
                  {{ $t('login.login') }}
                </v-btn>

                <app-bar-user-avatar
                  v-else
                  class="ms-2"
                  @logout="onLogout"
                />
              </div>
            </v-col>
          </v-row>
        </div>
      </v-card-text>

      <v-card-text class="pt-0">
        <info-card
          :title="dataComputed.sport_name"
          :subtitle="dataComputed.match_date_str"
          :date="`${formatStdDate(dataComputed.match_date_str, 'datetime-landing', true, $i18n.locale)} ${formatStdDate(dataComputed.start_time, 'time-short', true, $i18n.locale)} - ${formatStdDate(dataComputed.end_time, 'time-short', true, $i18n.locale)}`"
          :location="dataComputed.facility_name"
          :level="dataComputed.match_level_str === 'all' ? $t('game.any_level') : dataComputed.match_level_str"
          :price="dataComputed && dataComputed.total > 0 ? `${formatCurrency(dataComputed.total)}` : $t('status.free')"
          :gender-category="dataComputed.match_gender_str"
          :start-time="dataComputed.start_time_formatted ? `${dataComputed.start_time_formatted} (${formatTime(dataComputed.reservation_time)})` : ''"
          :cancel-date="dataComputed.cancel_reservation_date_str"
          :is-cancel="dataComputed.reservation_status === 'cancelled'"
          :can-cancel="dataComputed.can_cancel"
          :is-confirm="!!dataComputed.deposit_card"
          :confirm-code="depositCardComputed"
          :confirm-date="dataComputed.cancel_reservation_date_str"
          :is-paid="dataComputed.is_paid === 'Y'"
          :is-now="dataComputed.charge_reservation_date_str === 'now'"
          :payment-status="dataComputed.reservation_status === 'cancelled' ? dataComputed.reservation_status : dataComputed.payment_status"
        />
      </v-card-text>

      <v-card-text
        v-if="dataComputed && dataComputed.match_status === 'P'"
        class="pt-0"
      >
        <results-card
          :my-team-name="$t('game.my_team')"
          :rival-team-name="$t('game.rival_team')"
          :my-team-result="dataComputed.my_team === 'A' ? dataComputed.match_result_A : dataComputed.match_result_B"
          :rival-team-result="dataComputed.my_team === 'A' ? dataComputed.match_result_B : dataComputed.match_result_A"
        >
        </results-card>
      </v-card-text>

      <v-card-text
        v-if=" dataComputed && dataComputed.can_add_result"
        class="pt-0"
      >
        <action-card
          :title="$t('game.enter_results')"
          :subtitle="$t('game.enter_results_msg')"
          avatar-color="#ff3030"
          icon-color="#ffffff"
          :show-border="true"
          border-color="#ff323240"
          :show-arrow="true"
          @save="linkVideo"
        >
          <template #icon="{ attrs }">
            <img
              :src="icons.seal"
              height="20px"
              width="20px"
              alt="video"
              attrs="video"
              style="background-position: center center; margin-top: 4px"
              v-bind="attrs"
            />
          </template>
        </action-card>
      </v-card-text>

      <!-- TODO deek to video_detail -->
      <v-card-text
        v-if="dataComputed && dataComputed.video_id"
        class="pt-0"
      >
        <video-card
          :images="dataComputed.video_thumbnail ? [dataComputed.video_thumbnail] : []"
          @open="linkVideo(true)"
        />
      </v-card-text>

      <v-card-text class="pt-0">
        <player-list
          :teams="mapTeams(dataComputed.players_team_a , dataComputed.players_team_b, dataComputed.my_team)"
          :player-quantity="dataComputed.match_players_qty"
          :show-actions="true"
          :show-edit="dataComputed.can_edit_players && false"
          :is-private="privateLanding"
          :is-organizer="dataComputed.is_organizer === 'Y'"
          :is-player="dataComputed.is_player"
          :selected.sync="slotSelected"
          :disabled="dataComputed.is_joined || dataComputed.match_status !== 'N'"
          @refresh-match="fetchMatch"
          @download-app="openUpdateDownload"
          @open-game="linkVideo"
          @update-selected="(val) => slotSelected = val"
          @edit-players="onEditPlayers"
        />
      </v-card-text>

      <v-card-text v-if="false">
        <action-card
          :title="'Chat del partido'"
          :subtitle="'Todos los jugadores participan del chat'"
          :show-arrow="true"
        >
          <template #icon="{ attrs }">
            <v-icon
              color="white"
              class="ma-0"
              size="25"
              v-bind="attrs"
            >
              {{ icons.mdiMessageText }}
            </v-icon>
          </template>
        </action-card>
      </v-card-text>

      <v-card-text class="pt-0">
        <action-card
          :title="dataComputed.match_type === 'competitive' ? $t('game.ranked') : $t('game.not_ranked')"
          :subtitle="dataComputed.match_type === 'competitive' ? $t('game.ranked_msg') : $t('game.not_ranked_msg')"
          avatar-color="#F2F2F2"
        >
          <template #icon="{ attrs }">
            <img
              :src="icons.level"
              height="20px"
              width="20px"
              alt="level"
              attrs="level"
              style="background-position: center center"
              v-bind="attrs"
            />
          </template>
        </action-card>
      </v-card-text>

      <v-card-text
        v-if="dataComputed.include_video === 'Y'"
        class="pt-0"
      >
        <action-card
          :title="$t('game.with_video')"
          :subtitle="$t('game.with_video_msg')"
          avatar-color="#14ae5c40"
          icon-color="#14AE5C"
        >
          <template #icon="{ attrs }">
            <img
              :src="icons.video"
              height="20px"
              width="20px"
              alt="video"
              attrs="video"
              style="background-position: center center; margin-top: 4px"
              v-bind="attrs"
            />
          </template>
        </action-card>
      </v-card-text>

      <v-card-text
        style="padding-bottom: 140px;"
        class="pt-0"
      >
        <facility-card
          :facility-name="dataComputed.facility_name"
          :facility-address="dataComputed.facility_short_address"
          :field-name="dataComputed.field_name"
          :images="dataComputed.facility_images"
          :latitude="dataComputed.facility_latitude"
          :longitude="dataComputed.facility_longitude"
        />
      </v-card-text>

      <v-card-actions
        class="pay-card-action py-3 d-flex- flex-column"
      >
        <v-btn
          v-if="dataComputed.can_join || (dataComputed.is_joined && !dataComputed.paid)"
          class="text-body-1 font-weight-bold mb-2"
          style="border-radius: 33px; text-transform: initial;"
          color="primary"
          rounded
          large
          block
          @click="onPayBooking"
        >
          <span v-if="dataComputed.is_joined && !dataComputed.paid">{{ `${$t('game.pay_my_part')}` }}</span>
          <span v-else-if="dataComputed && dataComputed.total > 0">{{ `${$t('deek_game.join_for')} ${formatCurrency(dataComputed.total)}` }}</span>
          <span v-else>{{ $t('deek_game.join') }}</span>
        </v-btn>

        <v-btn
          v-else
          class="text-body-1 font-weight-bold mb-2"
          style="border-radius: 33px; text-transform: initial;"
          color="primary"
          rounded
          large
          block
          :outlined="!(dataComputed.is_joined && ['N', 'F'].includes(dataComputed.match_status))"
          @click="openUpdateShare(true)"
        >
          <span>{{ $t('game.share_invite') }}</span>
        </v-btn>

        <v-btn
          v-if="dataComputed.can_leave"
          class="text-body-1 font-weight-bold mb-2"
          style="border-radius: 33px; text-transform: initial;"
          color="primary"
          rounded
          large
          block
          outlined
          @click="openUpdateLeave(true)"
        >
          <span>{{ $t('game.unjoin') }}</span>
        </v-btn>
      </v-card-actions>
    </div>

    <download-app
      :is-open.sync="isDialogDownload"
      @open-close="openUpdateDownload"
      @save="defaultDownloadAppByOS"
    />

    <share
      :is-open.sync="isDialogShare"
      :link="dataComputed.match_share_url"
      :icon-size="28"
      :sport="dataComputed.sport_name"
      :date="dataComputed.match_date_str"
      :start-time="`${formatStdDate(dataComputed.start_time, 'time', true, $i18n.locale)} (${dataComputed.match_time} Mins)`"
      :location="dataComputed.facility_name ? `${dataComputed.facility_name} (${dataComputed.field_name})` : ''"
      @open-close="openUpdateShare"
    />

    <joined-match
      :is-open.sync="isDialogJoined"
      :title="joinedMessageFrom === 'pay-booking' ? $t('game.joined_match') : $t('game.joined_reservation')"
      :subtitle="joinedMessageFrom === 'pay-booking' ? $t('game.share_link') : $t('game.share_link_reservation')"
      :notify-new-slot="notifyNewSlot"
      :new-slot-message="$t('game.notify_new_slot')"
      @open-close="openUpdateJoined"
      @save="() => {
        openUpdateJoined(false)
        openUpdateShare(true)
        notifyNewSlot = false
      }"
    />

    <leave-match
      :is-open.sync="isDialogLeave"
      @open-close="openUpdateLeave"
      @save="onLeave"
    />
  </div>
</template>
<script>
import {
  mdiTrayArrowUp,
  mdiDotsVertical,
  mdiCircle,
  mdiMapMarker,
  mdiCancel,
  mdiPlay,
  mdiStarOutline,
  mdiVideo,
  mdiPencil,
  mdiMovieOpenPlayOutline,
  mdiHelpCircleOutline,
  mdiClose,
  mdiVideoOutline,
  mdiMessageText,
  mdiSeal,
} from '@mdi/js'
import {
  ref, computed, watch, onMounted, getCurrentInstance,
} from '@vue/composition-api'
import {
  useRouter,
  openTab,
  isAndroid,
  isIOS,
  defaultDownloadAppByOS,
  isDev,
  formatCurrency,
  formatStdDate,
} from '@core/utils'
import themeConfig from '@themeConfig'
import { useUtils } from '@core/libs/i18n'
import { avatarText } from '@core/utils/filter'
import {
  logUserActivity, getMatchGameCode, getMatchGameOpenCode, putHoldCardReservation, leaveMatchUser,
} from '@api'
import useCryptoJs from '@core/utils/useCryptoJs'
import useLogData from '@core/utils/useLogData'
import {
  showSuccess, showError,
} from '@/@core/utils/toasted'
import store from '@/store'

import JoinedMatch from '@/views/game/components/joined-match/JoinedMatch.vue'
import LeaveMatch from '@/views/game/components/leave-match/LeaveMatch.vue'
import AppBarUserAvatar from '@core/layouts/components/app-bar/AppBarUserAvatar.vue'
import InfoCard from '@/views/game/components/info-card/InfoCard.vue'
import ActionCard from '@/views/game/components/action-card/ActionCard.vue'
import ResultsCard from '@/views/game/components/results-card/ResultsCard.vue'
import VideoCard from '@/views/game/components/video-card/VideoCard.vue'
import PlayerList from '@/views/game/components/player-list/PlayerList.vue'
import FacilityCard from '@/views/game/components/facility-card/FacilityCard.vue'
import DownloadApp from '@/views/reservation/components/download-app/DownloadApp.vue'
import Share from '@/views/components/share/Share.vue'

import useGame from './useGame'

export default {
  name: 'Game',
  components: {
    AppBarUserAvatar,
    PlayerList,
    DownloadApp,
    InfoCard,
    ActionCard,
    ResultsCard,
    VideoCard,
    FacilityCard,
    Share,
    JoinedMatch,
    LeaveMatch,
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const { route, router } = useRouter()
    const { t, tInContext } = useUtils()
    const { userData } = useCryptoJs()
    const { logData, updateLogData, clearLogData } = useLogData()
    const { paymentMethods, mapTeams } = useGame()

    const USER_ACT_VISIT_MATCH_LANDING_APP = 'Visit Match Landing App'
    const USER_ACT_SHARE_MATCH_LANDING_APP = 'Share Match Landing App'
    const USER_ACT_VIEW_MATCH_LANDING_APP = 'View Match Landing App'
    const ipAddress = ref({})
    const slotSelected = ref(-1)
    const notifyNewSlot = ref(false)
    const joinedMessageFrom = ref(null)
    const userLogged = ref(null)

    const isAndroidComputed = computed(() => isAndroid())
    const isIOSComputed = computed(() => isIOS())

    const deepLinkVideo = ref('fanaty://video_detail/')
    const deepLinkVideoIOS = ref(`${isDev.value ? 'https://dev-console.fanaty.com' : 'https://console.fanaty.com'}/video_detail/`)

    const deepLinkMatch = ref('fanaty://match_detail/')
    const deepLinkMatchIOS = ref(
      `${isDev.value ? 'https://dev-console.fanaty.com' : 'https://console.fanaty.com'}/match_detail/`,
    )

    const dataLanding = ref({})
    const dataComputed = computed(() => dataLanding.value)
    const showComplexComputed = computed(
      () => dataComputed.value.complex_images
        && dataComputed.value.complex_images.length
        && dataComputed.value.complex_description,
    )
    const activeComputed = computed(() => dataComputed.value.match_status === 'Active')
    const basicComputed = computed(() => dataComputed.value.match_status === 'Basic')
    const depositCardComputed = computed(() => {
      if (dataComputed.value.deposit_card && dataComputed.value.deposit_card.ending4) return dataComputed.value.deposit_card.ending4

      return ''
    })
    const matchCode = ref(null)
    const matchId = ref(null)
    const videoId = ref(null)
    const privateLanding = ref(false)
    const isDialogLeave = ref(false)
    const isDialogJoined = ref(false)
    const isDialogDownload = ref(false)
    const isDialogPay = ref(false)
    const isDialogShare = ref(false)

    const logged = computed({
      get: () => store.state.auth.logged,
      set: value => {
        store.commit('appConfig/UPDATE_FOOTER_TYPE', value)
      },
    })

    const openUpdateShare = async val => {
      isDialogShare.value = val
    }

    const openUpdatePay = async val => {
      isDialogPay.value = val
    }

    const openUpdateLeave = async val => {
      isDialogLeave.value = val
    }

    const openUpdateJoined = async val => {
      isDialogJoined.value = val
    }

    const openUpdateDownload = async val => {
      isDialogDownload.value = val
    }

    const fetchMatch = async () => {
      let response = null
      if (userLogged.value) response = await getMatchGameCode(matchCode.value)
      else response = await getMatchGameOpenCode(matchCode.value)
      if (response.ok) {
        dataLanding.value = response.data
        const {
          joined, joinedMessage, joinedFrom, selected, pay,
        } = route.value.params
        if (userLogged.value && pay && dataLanding.value.can_join) {
          slotSelected.value = selected
          router.push({ name: 'pay-booking', params: { code: matchCode.value, selected: slotSelected.value } })
        }
        if (joined && dataLanding.value.is_joined) {
          notifyNewSlot.value = joinedMessage
          joinedMessageFrom.value = joinedFrom
          openUpdateJoined(true)
        }
        matchId.value = dataLanding.value.match_id
        videoId.value = dataLanding.value.video_id
        const finded = [...dataLanding.value.players_team_a, ...dataLanding.value.players_team_b].find(e => e.selected)
        slotSelected.value = finded && !dataLanding.is_joined ? finded.slot : -1
      } else {
        router.replace({
          name: 'match-not-found',
          params: {
            inactive: false,
            invalid: true,
          },
        })
      }
    }

    const onLogin = async (pay = false) => {
      logData.value.joinGame = 'NO'
      logData.value.matchCode = matchCode.value
      logData.value.slot = slotSelected.value
      logData.value.pay = pay
      logData.value.redirect = 'landing'
      logData.value.field = 'all'
      logData.value.date = 'all'
      logData.value.time = 'all'
      await updateLogData(logData.value)

      router.push({ name: 'auth-login-phone-email' })
    }

    const fetchLogUserActivity = async action => {
      await logUserActivity({
        ip_address: privateLanding.value ? null : ipAddress.value.ip,
        user_id: privateLanding.value ? userData.value.user_id : null,
        match_code: matchCode.value,
        reference: null,
        action,
      })
    }

    const onEditPlayers = () => {
      console.log('🚀 ~ onEditPlayers ~')
    }

    const linkVideo = (isVideo = false) => {
      const isAnd = isAndroid()
      const isIOScc = isIOS()
      // eslint-disable-next-line no-alert
      if (isDev.value)alert(`isAndroid value: ${isAnd}`)
      // eslint-disable-next-line no-alert
      if (isDev.value)alert(`isIOS value: ${isIOScc}`)
      if (isAndroid() || isIOS()) {
        const dLink = isVideo ? `${deepLinkVideo.value}${videoId.value}` : `${deepLinkMatch.value}${matchId.value}`
        const dLinkOISO = isVideo ? `${deepLinkVideoIOS.value}${videoId.value}` : `${deepLinkMatchIOS.value}${matchId.value}`

        if (isIOS()) {
          // eslint-disable-next-line no-alert
          if (isDev.value) alert(`deepLinkMatchIOS value: ${dLinkOISO}`)
          openTab(dLinkOISO)
        } else {
          // eslint-disable-next-line no-alert
          if (isDev.value) alert(`deepLinkVideoAndroid value: ${dLink}`)
          window.location = dLink
        }
      }

      openUpdateDownload(true)
    }

    const formatTime = time => {
      const [min] = time.split('mins')

      return `${min} mins`
    }

    const onPayBooking = async () => {
      if (privateLanding.value) {
        router.push({ name: 'pay-booking', params: { code: matchCode.value, selected: slotSelected.value } })
      } else {
        onLogin(true)
      }
    }

    const onPay = async value => {
      const resp = await putHoldCardReservation(matchCode.value, value)
      if (resp.ok) {
        openUpdatePay(false)
        await fetchMatch()
      } else showError(resp.message.text)
    }

    const initGame = async () => {
      await clearLogData()

      userLogged.value = await JSON.parse(localStorage.getItem('userData'))
      privateLanding.value = !!userLogged.value
      const { code } = route.value.params

      matchCode.value = code
      localStorage.setItem('matchCode', JSON.stringify(code))

      await fetchMatch()

      if (!userLogged.value) {
        await fetch('https://api.ipify.org/?format=json')
          .then(ipResponse => ipResponse.json())
          .then(async ipData => {
            ipAddress.value = ipData
          })
      }
    }

    const onLeave = async () => {
      const resp = await leaveMatchUser(matchCode.value)
      const msg = tInContext('game.leaved_match', vm)
      if (resp.ok) {
        await initGame()
        openUpdateLeave(false)
        showSuccess(msg)
      } else {
        showError(resp.message.text)
      }
    }

    const onLogout = async () => {
      await localStorage.removeItem('userData')
      paymentMethods.value = []

      await initGame()
    }

    watch([logged], async () => {
      await initGame()
    })

    onMounted(async () => {
      await initGame()
    })

    return {
      USER_ACT_VISIT_MATCH_LANDING_APP,
      USER_ACT_SHARE_MATCH_LANDING_APP,
      USER_ACT_VIEW_MATCH_LANDING_APP,
      ipAddress,
      userData,
      isDev,
      paymentMethods,
      deepLinkMatch,
      deepLinkMatchIOS,
      deepLinkVideo,
      deepLinkVideoIOS,
      matchId,
      videoId,
      slotSelected,
      notifyNewSlot,
      joinedMessageFrom,
      userLogged,

      matchCode,
      privateLanding,
      isDialogLeave,
      isDialogJoined,
      isDialogDownload,
      isDialogPay,
      isDialogShare,

      dataComputed,
      depositCardComputed,
      showComplexComputed,
      activeComputed,
      basicComputed,

      isAndroidComputed,
      isIOSComputed,

      appLogoTitle: themeConfig.app.logoTitleBlack,

      avatarText,
      openTab,
      onLogin,
      linkVideo,
      fetchMatch,
      openUpdateDownload,
      openUpdatePay,
      openUpdateShare,
      openUpdateLeave,
      openUpdateJoined,
      fetchLogUserActivity,
      onLogout,
      onPayBooking,
      onPay,
      defaultDownloadAppByOS,
      formatTime,
      formatCurrency,
      mapTeams,
      formatStdDate,
      onEditPlayers,
      onLeave,

      t,

      icons: {
        mdiTrayArrowUp,
        mdiDotsVertical,
        mdiCircle,
        mdiMapMarker,
        mdiCancel,
        mdiPlay,
        mdiStarOutline,
        mdiVideo,
        mdiPencil,
        mdiMovieOpenPlayOutline,
        mdiHelpCircleOutline,
        mdiClose,
        mdiVideoOutline,
        mdiMessageText,
        mdiSeal,
        level: require('@/assets/images/svg/level.svg'),
        video: require('@/assets/images/svg/video.svg'),
        seal: require('@/assets/images/svg/seal.svg'),
      },
    }
  },
}
</script>

<style lang="scss" scoped>
// @import '@core/preset/preset/pages/landing.scss';
@import '@core/preset/preset/pages/game.scss';

.theme--dark.v-divider {
  border-color: #333333;
}

.bg-grey {
  background-color: #979797;
}
</style>
